@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#BackToTop {
  position: fixed;
}

.img-thumbnail {
  cursor: pointer;
}

.img-thumbnail:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.1);
}
img.img-thumbnail {
  border: 0px;
  object-fit: scale-down;

}

.carousel-inner {
  height: 100%;
}


img#sirkkamerkkiimg {
  margin-left: 8px;
  object-fit: scale-down;
  padding: 8px 0px;
  height: 85px;

}

.nav-item a {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 16px 12px;
  color: #333;
  font-weight: 500;
}

a .dropdown-item{
  padding: 16px 12px;
}



.carousel.slide {
  margin-bottom: 32px;
}

a.dropdown-item {
  padding: 0px 12px;
}
.dropdown-item a {
  padding: 8px 12px;
}
.dropdown-menu.show {
  border: 0px;
}

.margin-top-32 {
  margin-top: 32px;
}
.margin-top-64 {
  margin-top: 64px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}
.margin-bottom-64 {
  margin-bottom: 64px;
}

.hide {
  -webkit-transition:1s;
  -moz-transition:1s;
  -ms-transition:1s;
  -o-transition:1s;
  transition:1s;
}

#Footer {
  padding: 16px 8px;
  text-align: center;
  vertical-align: center;
}


img.img-fluid.rounded-circle {
  object-fit: scale-down;
  height: 150px;
  width: 150px;
}

.modal img {
  max-inline-size: -webkit-fill-available;
}
.modal-90w { max-width: 90% !important; }


@media (max-width: 360px) {
  img.d-inline-block.align-center {
    width: 186px !important;
    height: 50px;
  }

  .nav-item {
    padding: 8px 8px;
    border-bottom: 1px solid #e1e1e1;
  }
  .nav-item a {
    padding: 4px 12px;
  }

  a.dropdown-item {
    padding: 0px 0px;
  }

  .carousel-item > img.w-100, .d-block.carousel.slide {
    object-fit: scale-down;
  }

  img#sirkkamerkkiimg {
    margin-left: 8px;
    height: 85px;
  }

  .nav-item a {
    padding: 4px 0px;
  }

  .dropdown-item a {
    padding: 0px 12px !important;
  }
}
@media (min-width: 361px) and (max-width: 575px) {
  .nav-item {
    padding: 8px 8px;
    border-bottom: 1px solid #e1e1e1;
  }

  .nav-item a {
    padding: 4px 0px;
  }

  .dropdown-item a {
    padding: 0px 12px !important;
  }

  .nav-item:last-child {
    border-bottom: 0px solid #e1e1e1;
  }

  #BackToTop {
    bottom: 5%;
    right: 5%;
  }

  .img-thumbnail {
    max-height: 240px !important;
  }

  .carousel-item > img.w-100, .d-block.carousel.slide {
    object-fit: scale-down;
  }

  img.d-inline-block.align-center {
    width: 240px;
    height: 50px;
  }

  .carousel-item {
    width: 100% !important;
  }

  img.yhteyistyokumppani {
    width: 100%;
    margin-bottom: 32px;
  }

  img.img-thumbnail {
    width: 50%;
    border: unset;
  }

  img#sirkkamerkkiimg {
    margin-left: 8px;
    height: 70px;
    display: inline-block;

  }


  .dropdown-item a {
    padding: 0px 12px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .nav-item {
    padding: 8px 8px;
    border-bottom: 1px solid #e1e1e1;
  }

  .nav-item:last-child {
    border-bottom: 0px solid #e1e1e1;
  }
  #BackToTop {
    bottom: 5%;
    right: 5%;
  }

  .img-thumbnail {
    max-height: 250px !important;
  }

  img.d-inline-block.align-center {
    height: 47px;
  }

  img.yhteyistyokumppani {
    width: 46%;
    margin-bottom: 32px;
  }

  img.img-thumbnail {
    width: 25%;
    border: unset;
  }

  .sirkkamerkki {
    text-align: left;
  }

  img#sirkkamerkkiimg {
    margin-left: 8px;
    height: 70px;
  }

  .nav-item a {
    padding: 8px 0px;
  }

  .dropdown-item a {
    padding: 0px 12px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .nav-item {
    padding: 8px 8px;
    border-bottom: 1px solid #e1e1e1;
  }
  .nav-item:last-child {
    border-bottom: 0px solid #e1e1e1;
  }
  .nav-item a {
    padding: 8px 0px;
  }

  .dropdown-item a {
    padding: 0px 12px !important;
  }

  #BackToTop {
    bottom: 5%;
    right: 5%;
  }

  .img-thumbnail {
    max-height: 250px !important;
  }

  img.d-inline-block.align-center {
    width: 433px;
    height: 67px;
  }

  img.yhteyistyokumppani {
    width: 31%;
    height: auto;
  }

  img#sirkkamerkkiimg {
    margin-left: 8px;
    height: 85px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .nav-item {
    padding: 16px 0px;
  }
  #BackToTop {
    bottom: 4%;
    right: 8%;
  }

  .img-thumbnail {
    height: 150px !important;
    object-fit: scale-down;
  }

  img.yhteyistyokumppani {
    width: 20%;
    height: auto;
  }

  img.d-inline-block.align-center {
    width: 273px;
  }
}

@media (min-width: 1200px) {
  .nav-item {
    padding: 16px 8px;
  }

  #BackToTop {
    bottom: 5%;
    right: 15%;
  }

  .img-thumbnail {
    height: 150px !important;
  }

  .carousel-item > img.w-100, .d-block.carousel.slide {
  }

  img.yhteyistyokumppani {
    width: 18%;
    height: auto;
  }
}

@media (min-width: 576px) {
  .modal-dialog.portrait {
    max-width: 38VW !important;
  }
  .modal-dialog.landscape {
    min-width: 70VW !important;
  }
}